const filterTableTranslateKey = {
  creationTime: 'creation_time',
  lastModifiedTime: 'last_modified_time',
  lastModifiedBy: 'last_modified_by',
  pfhNumber: 'pfh_number',
  asset: 'asset',
  assetAmount: 'asset_amount',
  status: 'status',
  referenceNumber: 'reference_number',
  exchangeRate: 'exchange_rate',
  creditCurrency: 'credit_currency',
  creditAmount: 'credit_amount',
  step: 'step',
  sourceTransferOrderId: 'source_transfer_order_id',
  targetTransferOrderId: 'target_transfer_order_id',
  createdBy: 'created_by',
  operation: 'operation',
  confirmToRetryTheRecord: 'confirm_to_retry_the_record',
  retry: 'retry',
  retrySuccessfully: 'retry_successfully',
  swapType: 'swap_type'
};

export { filterTableTranslateKey };
