import { useQuery } from 'react-query';

import { GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid';
import { AgentPortalFeatureCode } from '@wallet-manager/pfh-pmp-node-def-types';

import APIs from '../../../../api';
import TableTemplate from '../../../../components/Layout/TableTemplate';
import { usePermission } from '../../../../hooks';
import { useGenTableColumns } from '../../../../hooks/useTable';
import useTranslation, { Iprefix } from '../../../../hooks/useTranslation';
import { useZusDialogStore } from '../../../../zustand/store';
import { useListMapping, useZusParams } from '../hooks';
import { filterTableTranslateKey as TableTK } from '../mappings/translate';
import { useCallback } from 'react';
import ButtonMenu from '../../../../components/Button/ButtonMenu';
import { IapiGetAllRes } from '../types';
import { EnumCreditSwapStatus } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/SwapAgent';

const api = {
  table: APIs.programAgentManagement.creditSwapRecord.getAll,
  export: APIs.programAgentManagement.creditSwapRecord.getExport,
};

export default function Table() {
  const { hasPermission } = usePermission();
  const translatePrefix: Iprefix = 'creditSwapRecord';
  const exportFileName = 'Credit Swap Record';
  const exportPermissionKey = AgentPortalFeatureCode.ProgramAgentManagement.CreditSwapRecord.Export;

  const zusParams = useZusParams();
  const zusDialog = useZusDialogStore();

  const { t, tc } = useTranslation(translatePrefix);

  const listRes = useQuery({
    queryKey: [translatePrefix, zusParams.body, zusParams.refetchCounter],
    queryFn: () => {
      zusDialog.openExtra('loadingDialog');
      return api.table(zusParams.body);
    },
    onSettled: () => zusDialog.closeExtra(),
  });

  const { rows = [], count = 0 } = listRes.data || {};

  const tableMapping = useListMapping('table');
  const exportMapping = useListMapping('export');

  const omitKeys: string[] = [];

  const content: GridRowsProp = tableMapping(rows, omitKeys);

  const renderOperationCell = useCallback((params: GridRenderCellParams) => {
    const getAllData = params.row['rawData'] as IapiGetAllRes;
    const shouldDisableRetry = !(
      getAllData.status === EnumCreditSwapStatus.ExecutedFailed ||
      getAllData.status === EnumCreditSwapStatus.Stucking
    );

    return (
      <ButtonMenu
        title={t(TableTK.operation)}
        options={[
          {
            name: t(TableTK.retry),
            onClickFn: () => {
              zusDialog.open('creditSwapRecordRetryDialog', getAllData);
            },
            isDisabled: shouldDisableRetry,
          },
        ]}
      />
    );
  }, []);

  const { columns } = useGenTableColumns([
    { translateKey: TableTK.operation, renderCell: renderOperationCell },
    { translateKey: TableTK.referenceNumber, minWidth: 150 },
    { translateKey: TableTK.pfhNumber, minWidth: 150 },
    { translateKey: TableTK.swapType, minWidth: 150 },
    { translateKey: TableTK.asset },
    { translateKey: TableTK.assetAmount, minWidth: 150 },
    { translateKey: TableTK.exchangeRate },
    { translateKey: TableTK.creditCurrency },
    { translateKey: TableTK.creditAmount },
    { translateKey: TableTK.status, minWidth: 150 },
    { translateKey: TableTK.step, minWidth: 150 },
    { translateKey: TableTK.sourceTransferOrderId, minWidth: 150 },
    { translateKey: TableTK.targetTransferOrderId, minWidth: 150 },
    { translateKey: TableTK.createdBy, minWidth: 150 },
    { translateKey: TableTK.creationTime },
    { translateKey: TableTK.lastModifiedBy },
    { translateKey: TableTK.lastModifiedTime },
  ]);

  return (
    <TableTemplate
      exportBtn={{
        fileName: exportFileName,
        permissionKey: exportPermissionKey,
        apiFn: api.export,
        omitKeys: ['rawData'],
        mappingFn: exportMapping,
      }}
      zusParams={zusParams}
      count={count}
      content={content}
      columns={columns}
    />
  );
}
