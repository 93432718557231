import errorCode from '../../utils/errorCode.json';
import COUNTRY_CODE_LIST from './countryCodeList.json';

const json = {
  noPrefix: {},
  errorCode: errorCode.zh_cn,
  countryCodes: COUNTRY_CODE_LIST, // Reference: https://github.com/joonhocho/country-calling-code

  menu: {
    version: '版本',
    homepage: '主頁',
    accountSetting: '帳號設置',
    toggleFilterButtonCollapse: '折疊',
    toggleFilterButtonExpand: '展開',
    toggleBtnCollapse: '折疊',
    toggleBtnExpand: '展開',
    dashboard: '概覽',
    setting: '設置',
    programAgentManagement: '大代理管理',
    distributorAgentManagement: '分銷代理管理',
    clientManagement: '客戶管理',
    reportAms: '報表',
    rebate: '紅利',
    getWalletAddress: '獲取錢包地址',
    RequestFund: '資金申請',
    exchangeRateAms: '匯率',
    userSetting: '用戶設置',
    distributorAgentList: '分銷代理列表',
    custodyWallet: '收款錢包',
    custodyRecord: '收款記錄',
    creditSwapRecord: '客戶自動兌換記錄',
    roleSetting: '角色設置',
    clientApplication: '客戶申請',
    programAgentReportDetail: '大代理報表 (詳細)',
    distributorAgentReportDetail: '分銷代理報表 (詳細)',
    clientReportDetail: '客戶報表 (詳細)',
    programAgentReport: '大代理報表',
    distributorAgentReport: '分銷代理報表',
    clientReport: '客戶報表',
    programAgentDeposit: '大代理充值',
    programAgentWallet: '大代理錢包',
    creditAllocation: '分銷代理信用分配',
    distributorAgentProgram: '分銷代理計劃',
    creditAllocationRecord: '信用分配記錄',
    distributorAgentWallet: '分銷代理錢包',
    clientList: '客戶列表',
    creditAllocationReport: '信用分配記錄',
    rebateDistributionTask: '紅利分配任務',
    rebateRecord: '分配紅利記錄',
    monthlyRebateReport: '紅利月報表',
    exchangeRateConfiguration: '匯率調整配置',
    customerSpendingReport: '客戶消費報表',
    feeAdjustmentRate: '手續費調整率',
    programList: '計劃清單',
    programAgentRebateWallet: '大代理紅利錢包',

    downloadAppButton: '下載App',
  },

  common: {
    welcome: '歡迎',
    logout: '登出',
    confirm: '確定',
    edit: '修改',
    cancel: '取消',
    start_date: '開始時間',
    end_date: '結束時間',
    no_data_export: '沒有可導出的數據',
    phSelection: '請選擇%{fieldName}',
    phInputField: '請輸入%{fieldName}',
    input_field_check_number: '請輸入有效的%{fieldName}',
    phSelectionOptional: '請選擇%{fieldName}(非必填)',
    phInputFieldOptional: '請輸入%{fieldName}(非必填)',
    search: '查詢',
    reset: '重置',
    export: '導出',
    table: '列表',
    successCreate: '成功生成%{target}',
    autoLogoutAlert: '因為另一個帳號在同一瀏覽器登錄，此帳號已登出',
    logoutAlert: '您的帳號已登出',
    alert_no_permission: '沒有相關權限',
    programName: '計劃名稱',
    customerId: 'CustomerId',
    status: '狀態',
    customerNumber: 'CustomerNumber',
    merchantId: 'MerchantId',
    createdBy: '創建人',
    creationDate: '創建日期',
    lastModifiedBy: '最後更新人',
    lastModifiedTime: '最後更新時間',
    lastModifiedDate: '最後更新日期',
    latestVersion: '最新版本',
    applicationNumber: '申請編號',
    tcspCustomerApplicationType: 'TCSP申請類型',
    txHash: '交易Hash',
    referenceNumber: '參考編號',
    currency: '貨幣',
    approvalProgressDialogTitle: '審批進度',
    no_relevant_permission: '沒有相關權限',
    no_same_operator_creator: '創建人和審批人不能相同',
    no_same_approver: '審批人和操作人不能相同',
    request_approved: '申請已審批',
    approve_successful: '審批成功',
    request_rejected: '申請已拒絕',
    remarks: '備註',
    confirm_btn: '確定',
    approve_btn: '審批',
    reject_btn: '拒絕',
    approve_reject_request_btn: '審批/拒絕',
    viewDetail: '查看明細',
    approve_reject_dialog_title: '審批/拒絕',
    chainName: '鏈名稱',
    selectSearchLabel: '輸入內容以查詢',
    orderId: '訂單編號',
    transactionType: '交易類型',
    amount: '金額',
    sendingAddress: '發送地址',
    receivingAddress: '接收地址',
    creditAmount: '信用金額',
    exchangeRate: '匯率',
    transactionHash: '交易Hash',
    creationTime: '創建時間',
    cancel_btn: '取消',
    approveReject: '審批/拒絕',
    applicationDetails: '申請明細',
    close: '關閉',
    no_data_found: '沒有相關數據',
    auto: '自動',
    create_request: '創建請求',
    not_allow_to_pick: 'Notallowtopick%{object}',
    hours: '小時',
    minutes: '分鐘',
    seconds: '秒鐘',
    create_request_success: '已成功創建請求',
    create_record_success: '已成功創建記錄',
    copied: '已複製!',
    clientId: '客戶ID',
    create_record: '創建記錄',
    adjustmentAmount: '修改金額',
    ph_program_name_add_on: 'forviewingoptionsinAsset',
    createRecord: '創建記錄',
    validate_below_zero: '%{fieldName}的值需大於0',
    validate_zero: '%{fieldName}不能等於0',
    no_changes_made_warning: '請修改',
    partnerName: '合作方姓名',
    homeCurrency: '主貨幣',
    adjustmentMode: '調整模式',
    customer_number_not_found: '客戶號碼不存在',
    successful_edit: '編輯成功',
    repaymentCurrency: '還款貨幣',
    customerLevel: '客戶層級',
    rewardRate: '回贈率',
    editRecord: '編輯記錄',
    edit_btn: '編輯',
    request_record_created: '已成功創建請求',
    network: '所屬網絡',
    yes: '是',
    no: '否',
    next: 'next',
    optional_field: '%{fieldName}(非必填)',
    ledgerClientId: 'Ledger客戶ID',
    pfhNumber: 'PFH編號',
    lastLedgerTransactionId: '最後Ledger交易ID',
    fromCurrency: '基礎貨幣',
    toCurrency: '相對貨幣',

    distributorAgentId: '分銷代理ID',
    agentType: '代理類型',
    agentRole: '代理角色',
    agentStatus: '代理狀態',
    agentLoginEmail: '登錄電郵',
    roles: '角色',
    agentName: '名稱',
    noRecord: '沒有相關記錄',
    month: '月份',
    transactionAmount: '交易金額',
    cannot_exceed_warning: '%{fieldName}不能超過%{value}',
    program_name: '計劃名稱',
    question_type: '申請表類型',
    idv_method: ' IDV方式',
    rebateStatus: '狀態',

    from: '從',
    to: '到',
    rebateTaskStatus: '狀態',
    taskId: '任務ID',

    rebateMode: '分配紅利模式',
    download: '下載',

    am: '上午',
    pm: '下午',
    programNameDisplay: '計劃名稱（顯示)',
    tokenNotFound: '找不到令牌',
    display_records_limit: '最多顯示%{limit}條記錄',

    programDisplayName: '計劃顯示名稱',
    questionType: '申請表類型',
    kycIdvMethod: 'IDV方式',
    programCurrency: '計劃貨幣',
    assignedTo: '已分配到',
  },

  dashboard: {
    network: '所屬網絡',
    qrCode: '二維碼',
    walletAddress: '錢包地址',
    hkdmBalanceTitle: '大代理HKDM餘額',
    hkdm: 'HKDM',
    hkd: 'HKD',
    distributionOverview: '分銷代理概況',
    totalNoOfDistributorAgent: '分銷代理總數',
    totalNoOfClient: '客戶總數',
    totalNoOfActiveAgent: '活躍分銷代理總數',
    totalNoOfActiveAgentSubtitle: ' 顯示最近30天內至少創建一個客戶申請的代理總數',
    totalNoOfActiveClient: '活躍客戶總數',
    totalNoOfActiveClientSubtitle: '顯示最近30天內至少完成一筆交易的客戶總數',
    programAgentOverview: '大代理概況',
    totalCustodyAssetTop5: '前5名託管資產總額',
    totalRebateAmount: '紅利總金額',
    totalPurchaseAmount: '消費總金額',
    SevenDay: '最近7日',
    FourteenDay: '最近14日',
    ThirtyDay: '最近30日',
    assetOverview: '資產總覽',
    totalProgramNumber: '計劃總數',
    viewDetails: '查看詳情',
  },

  programAgentDeposit: {
    programName: '計劃名稱',
    currency: '貨幣',
    qrCode: '二維碼',
    walletAddress: '錢包地址',
  },

  login: {
    email: '電郵',
    password: '密碼',
    send: '發送',
    resend: '重新發送',
    emailNotExistError: '電郵地址不存在',
    programAgentId: '大代理ID',
    enterEmail: {
      email: '電郵地址',
    },
    emailVerification: {
      verificationCode: '驗證碼',
      enterVerificationCode: '請輸入驗證碼',
    },
    resetPassword: {
      successResetAlert: '已成功重置密碼',
      password: '密碼',
      confirmPassword: '確認密碼',
      characterLimit: '8-40數位',
      requiredUpperCase: '至少一個大寫字母',
      requiredSymbol: '至少一個符號',
      requiredNumber: '至少1個數字',
    },
    enterPassword: {
      password: '密碼',
    },
    enterPasswordWithVerify: {
      trustDevice: '信任此裝置',
      verificationCodeCardTitle: '驗證您的設備',
      verificationCodeCardSubtitle: '點擊「發送」，將驗證碼發送到您的電郵地址',
    },
    forgotPassword: '忘記密碼?',
    enterForgotPasswordEmail: {
      title: '忘記密碼?',
      email: '電郵地址',
      enterVerifiedEmail: '請輸入已註冊的電郵地址以接收驗證碼',
      emailNotFound: '電郵地址不存在',
    },
  },

  validation: {
    selectRequired: '請選擇%{fieldName}',
    inputRequired: '請輸入%{fieldName}',
    shouldBeLargerThanZero: '%{fieldName}需大於0',
    requiredLength: '%{fieldName}需在%{min}和%{max}之間',
    requiredUpperCase: '%{fieldName}必須最少有一個大寫字母',
    requiredSymbol: '密碼必須最少有一個符號',
    requiredContainNumber: '密碼必須最少有一個數字',
    confirmPassword: '密碼和確認密碼必須相同',
    loginEmailInvalid: '電郵地址格式無效',
  },

  component: {
    typeToSearch: '輸入以查詢',
    noRecords: '沒有相關記錄',
    itemTotalStart: '共',
    itemTotalEnd: '條記錄',
    clickSearchToViewRecords: '點擊”查詢”以搜索記錄',
    startMonth: '開始月份',
    endMonth: '結束月份',
  },

  accountSetting: {
    email: '登錄電郵',
    timezone: '時區',
    language: '語言',
    password: '密碼',
    changePassword: '修改密碼',

    creationTime: '創建時間',
    programName: '計劃名稱',
    programDisplayName: '計劃顯示名稱',
    kycIdvMethod: 'IDV方式',
    questionType: '申請表類型',
    programCurrency: '計劃貨幣',
    assignedTo: '已分配到',
    lastModifiedTime: '最後更新時間',
  },
  changePassword: {
    changePassword: '修改密碼',
    confirm: '確定',
    currentPassword: '現有密碼',
    newPassword: '新密碼',
    confirmNewPassword: '確定新密碼',
    confirmPassword: '確定新密碼',
    cancel: '取消',
    changedSuccess: '密碼已更改',
    passwordRequirements: '密碼需包含8-40數位，至少一個大寫字母，一個符號和一個數字',
    characterLimit: '8-40數位',
    requiredUpperCase: '至少一個大寫字母',
    requiredSymbol: '至少一個符號',
    requiredNumber: '至少1個數字',
  },
  enumConstants: {
    en: 'English',
    zh_cn: '繁中',
    timezone0: '+0',
    timezone1: '+1',
    timezone2: '+2',
    timezone3: '+3',
    timezone4: '+4',
    timezone5: '+5',
    timezone6: '+6',
    timezone7: '+7',
    timezone8: '+8',
    timezone9: '+9',
    timezone10: '+10',
    timezone11: '+11',
    timezone12: '+12',
    timezone13: '-11',
    timezone14: '-10',
    timezone15: '-9',
    timezone16: '-8',
    timezone17: '-7',
    timezone18: '-6',
    timezone19: '-5',
    timezone20: '-4',
    timezone21: '-3',
    timezone22: '-2',
    timezone23: '-1',
    Rejected: '已拒絕',
    Pending: '待處理',
    Approved: '已審批',
    Executing: '執行中',
    onHold: '暫時擱置',
    ExecutedSuccess: '執行成功',
    ExecutedFailed: '執行失敗',
    CustomerWallet: '客戶錢包',
    MerchantWallet: '商戶錢包',
    AdjustCredit: '信用調整',
    Repayment: '還款',
    SetCredit: '配置信用額',
    CreditIn: 'CreditIn',
    DebitOut: 'DebitOut',
    Adjustment: '調整',
    SetValue: '設置金額',
    MerchantTx: '商戶交易',
    CustomerTx: '客戶交易',
    Auto: '自動',
    ByRequest: '根據請求',
    assigned: '已分派',
    created: '已創建',
    deleted: '已刪除',
    Failed: '失敗',
    Enabled: '已啟動',
    Disabled: '已關閉',
    enable: '已啟動',
    disable: '已關閉',
    Enable: '啟動',
    Disable: '關閉',
    Blacklisted: '已進入黑名單',
    yes: '是',
    no: '否',
    Yes: ',是',
    No: '否',
    Mr: '先生',
    Mrs: '女士',
    Ms: '小姐',
    Male: '男',
    Female: '女',
    SelfOwned: '本人持有',
    Mortgaged: '貸款-月供',
    Rented: '月租',
    LivingWithRelative: '與親戚同住',
    Others: '其他',
    Work: '工作用途',
    Expenses: '消費用途',
    Investment: '投資用途',
    Insurance: '支付保險',
    Single: '單身',
    Married: '已婚',
    Divorced: '離婚',
    Widowed: '喪偶',
    SeniorManagement: '高級管理層',
    Vp: '總裁/副總裁',
    Management: '管理層',
    Officer: '主任級',
    Clerk: '職員',
    Admin: '行政人員',
    SelfEmployed: '自僱',
    Fulltime: '全職受僱',
    Parttime: '兼職受僱',
    Unemployed: '待業',
    Student: '學生',
    Housewife: '主婦',
    Retired: '退休',
    University: '大學或以上',
    College: '大專',
    Secondary: '中學',
    IdCard: '身份證',
    Passport: '護照',
    Transport: '航天或運輸',
    Computer: 'Computer電腦',
    It: '資訊科技或電信',
    AgricultureOrFisheries: '農業與漁業',
    Education: '教育',
    Pharmaceutical: '製藥',
    FoodBeverage: '餐飲餐廳',
    HealthCare: '醫療',
    Hospitality: '酒店業',
    Media: '新聞傳媒行業',
    Energy: '能源',
    Manufacturing: '製造業',
    Music: '音樂',
    Banking: '銀行業',
    Finance: '保險或金融業',
    Electronics: '電子業',
    ProfessionalServices: '專業服務',
    Construction: '建造業',
    GovEntities: '政府',
    Regulations: '法規行業',
    Bars: '酒吧',
    SnackStore: 'SnackStores',
    Jewelries: 'JewelriesRetails',
    Retails: 'PreciousMetalRetailsorDealing',
    Beauty: 'BeautyShop',
    Liquor: 'LiquorStores',
    Cigarette: 'CigaretteDistribution',
    NonGovCharitable: 'Non-GovernmentCharitableOrganization',
    WetMarket: 'WetMarket',
    FlowerMarket: 'FlowerMarket',
    Market: 'Market',
    None: 'None',
    Retry: 'Retry',
    Success: 'Success',
    Onfido: 'Onfido',
    Manual: 'Manual',
    Skip: 'Skip',
    Skipped: 'Skipped',
    Registration: 'Registration',
    CreateAccount: 'CreateAccount',
    UpdateInfo: 'UpdateInfo',
    UpdateEmailAddress: 'UpdateEmailAddress',
    UpdatePhoneNo: 'UpdatePhoneNo.',
    Submitted: 'Submitted',
    OnChainDeposit: 'OnChainDeposit',
    API: 'API',
    DeductBalance: 'DeductBalance',

    DeductBalanceSuccess: '扣除餘額成功',
    CreateRepayment: 'CreateRepayment',
    RepaymentSuccess: 'RepaymentSuccess',
    ExcecuteedFailed: '執行失敗',
    Cancelled: '已取消',
    Init: '初始',
    Excecuting: '執行中',
    ExcecutedSuccess: '執行成功',
    FastConfirmedFail: '初步確認失敗',
    ConfirmedFail: '確認失敗',
    Unconfirmed: '未確認',
    ConfirmedSuccess: '確認成功',
    Invalid: '無效',
    FastConfirmedSuccess: '初步確認成功',
    NoData: '-2 沒有數據',
    NoAssessment: '-1 沒有評估',
    NoRiskDetected: '0 未檢測到風險',
    Info: '1 訊息',
    Caution: '2 需注意',
    Classic: '經典',
    TCSP: 'TCSP',
    AssetCustody: 'AssetCustody',
    ManualReview: 'ManualReview',
    ManualSuccess: 'ManualSuccess',
    ByScheduler: 'ByScheduler',
    RejectedAndReleased: 'RejectedandReleased',
    Percentage: '按百分比調整',
    AbsoluteValue: '按絕對值調整',
    OverwriteValue: '直接輸入數值',
    IdentityCard: '香港身份證',
    DrivingLicence: 'DrivingLicence',
    ShareCode: 'ShareCode',
    VoterId: 'VoterID',
    Ssn: 'SSN',
    SocialInsurance: 'SocialInsurance',
    TaxId: 'TaxID',
    InitialCredit: 'InitialCredit',
    UserRequest: 'UserRequest',
    Verified: 'Verified',
    repayment: 'Repayment',
    reward: 'Reward',
    Completed: 'Completed',
    InProcess: 'InProcess',
    Unknown: 'Unknown',
    Critical: '5 極高',
    High: '4 高',
    Medium: '3 中',
    SystemBlock: 'SystemBlock',
    BackofficeBlock: 'BackOfficeBlock',
    CustomerBlock: 'CustomerBlock',
    LostCard: 'LostCard',
    blocked: 'Blocked',
    pending: 'Pending',
    released: 'Released',
    replaced: 'Replaced',
    reissued: 'Reissued',
    unknown: 'Unknown',
    Lost: 'Lost',
    Expired: 'Expired',
    Damaged: 'Damaged',
    Replace: 'Replace',
    suspended: 'Suspended',
    fraud: 'Fraud',
    Fraud: 'Fraud',
    lostStolen: 'Lost/Stolen',
    OperationFailed: 'OperationFailed',
    RequestFailed: 'RequestFailed',
    Submitting: 'Submitting',
    Unassigned: 'Unassigned',
    loststolen: 'Lost/Stolen',
    Stucking: '異常',
    // TODO:
    IncreaseCreditSuccess: '增加信用額成功',
    QueryProgramCurrencyAndExhangeRate: '查詢貨幣及匯率',
    RequestTransferOut: '請求轉帳',
    TransferOutSuccess: '請求轉帳成功',
    RequestIncreaseCredit: '請求增加信用額',
    PercentageByProfit: '以紅利百分比分配',
    PercentageByTxAmount: '以交易金額百分比分配',
    CreditSwapAutoAllocation: '自動兌換',
    ManualAllocation: '人工分配',
    PaAutoSwapAllocation: '大代理自動兌換',
    ClientAutoSwap: '客戶自動兌換',
    PaAutoSwap: '大代理自動兌換',
    ExcutedSuccess: '執行成功',
    SubmitCustomerApplication: '已提交客戶申請',
    AssignCustomerNumber: '分配PFH號碼',
    SubmitTcspApplication: '已提交TCSP申請',
    UploadFrontDocument: '上傳文件 (正面)',
    UploadBackDocument: '上傳文件 (背面)',
    CreateCustomer: '創建客戶',
    ExecuteFailed: '執行失敗',
    Redeem: '兌換',
    Reward: '回贈',
    Allocation: '信用額分配',
    Deposit: '存款',
    FreezeBalance: '凍結餘額',
    Rebate: '紅利',
    SwapCredit: '兌換信用額',
    Transfer: '轉帳',
    TransferFee: '轉帳費用',
    UnFreezeBalance: '解凍餘額',
    Withdraw: '取款',
    WithdrawFee: '取款費用',
    WithdrawFeeRefund: '取款費用退款',
    WithdrawRefund: '取款退款',
    Other: '其他',
  },

  EnumCreditAllocationStatus: {
    ExecutedSuccess: '執行成功',
    ExecuteFailed: '執行失敗',
    Rejected: '不獲批准',
    Pending: '待辦',
    Approved: '獲批',
    Executing: '執行中',
    Stucking: '異常',
  },
  EnumRebateTaskStatus: {
    Failed: '失敗',
    Rejected: '不獲批准',
    InsufficientFund: '資金不足',
    ReportGenerating: '報表生成中',
    ReportReady: '報表已生成',
    Approved: '獲批',
    Init: '初始',
    Executing: '執行中',
    Completed: '已完成',
    Stucking: '異常',
  },
  EnumRebateStep: {
    Start: '開始',
    GeneratingCustomerMonthlyRebate: '客戶月報表生成中',
    GeneratingDaMonthlyRebate: '分銷代理月報表生成中',
    GeneratingRebates: '紅利生成中',
    Completed: '已完成',
  },
  EnumRebateStatus: {
    Init: '初始',
    Executing: '執行中',
    Completed: '已完成',
    Stucking: '異常',
    Skipped: '已跳過',
  },
  EnumRebateType: {
    FreezeRebateFund: '凍結紅利資金',
    DaRebate: '分銷代理紅利',
    CustomerRebate: '客戶紅利',
  },
  EnumAgentType: {
    ProgramAgent: '大代理',
    DistributorAgent: '分銷代理',
    Unknown: '未知',
  },
  EnumQuestionType: {
    Long: '一般客戶',
    Short: '代理客戶',
  },
  EnumIdvMethod: {
    Onfido: 'Onfido',
    Manual: '人工驗證',
    Skip: '跳過IDV',
  },
  EnumRebateMode: {
    PercentageByTxAmount: '以交易金額百分比分配',
    PercentageByProfit: '以紅利百分比分配',
  },
  custodyWallet: {
    balanceTab: {
      title: '餘額',
      creationTime: '創建時間',
      lastModifiedTime: '最後更新時間',
      ledgerClientId: 'Ledger客戶ID',
      clientId: '客戶ID',
      pfhNumber: 'PFH號碼',
      currency: '貨幣',
      balance: '餘額',
      frozenBalance: '凍結餘額',
      lastLedgerTransactionId: '最後Ledger交易ID',
    },
    historyTab: {
      title: '歷史',
      creation_time: '創建時間',
      last_modified_time: '最後更新時間',
      ledger_client_id: 'Ledger客戶ID',
      pfh_number: 'PFH號碼',
      currency: '貨幣',
      sending_address: '發送地址',
      receiving_address: '接收地址',
      transaction_hash: '交易Hash',
      reference_number: '參考編號',
      last_ledger_transaction_id: 'Last Ledger ID',
      amount: '金額',
      post_balance: '後期餘額',
      frozen_balance: '凍結餘額',
      risk_level: '風險等級',
      transaction_status: '交易狀態',
      transaction_type: '交易類型',
      sending_client_id: '發送方客戶ID',
      receiving_client_id: '接收方客戶ID',
    },
  },

  programAgentWallet: {
    balanceTab: {
      title: '餘額',
      history_button: '歷史',
      operation: '操作',
      creationTime: '創建時間',
      lastModifiedTime: '最後更新時間',
      ledgerClientId: 'Ledger客戶ID',
      clientId: '客戶ID',
      pfhNumber: 'PFH號碼',
      currency: '貨幣',
      balance: '餘額',
      frozenBalance: '凍結餘額',
      lastLedgerTransactionId: '最後Ledger交易ID',
    },
    historyTab: {
      title: '歷史',
      order_id: '訂單ID',
      order_type: '訂單類型',
      from: '從',
      to: '到',
      currency: '貨幣',
      transaction_amount: '交易金額',
      post_balance: '交易後餘額',
      ledger_transaction_id: 'Ledger交易ID',
      remarks: '備註',
      creation_time: '創建時間',
    },
  },

  programAgentRebateWallet: {
    balanceTab: {
      title: '餘額',
      history_button: '歷史',
      operation: '操作',
      creationTime: '創建時間',
      lastModifiedTime: '最後更新時間',
      ledgerClientId: 'Ledger客戶ID',
      clientId: '客戶ID',
      pfhNumber: 'PFH號碼',
      currency: '貨幣',
      balance: '餘額',
      frozenBalance: '凍結餘額',
      lastLedgerTransactionId: '最後Ledger交易ID',
    },
    historyTab: {
      title: '歷史',
      order_id: '訂單ID',
      order_type: '訂單類型',
      from: '從',
      to: '到',
      currency: '貨幣',
      transaction_amount: '交易金額',
      post_balance: '交易後餘額',
      ledger_transaction_id: 'Ledger交易ID',
      remarks: '備註',
      creation_time: '創建時間',
    },
  },

  exchangeRate: {
    createExchangeRate: '創建匯率',
    currentExchangeRate: '現時匯率',
    nextExchangeRate: '下次匯率',
    startFrom: '開始時間',
    infinity: '一直使用現時匯率',
    editSuccessAlert: '已成功修改',
    noChangeAlert: '請修改',
    fromCurrency: '基礎貨幣',
    toCurrency: '計價貨幣',
    earlierThanApiStartFromError: '選取的時間必須為當前時間或之後',
  },

  userSetting: {
    distributorAgentId: '分銷代理',
    agentType: '代理類型',
    loginEmail: '登錄電郵',
    role: '角色',
    roles: '角色',
    status: '狀態',
    remarks: '備註',
    createdBy: '創建人',
    creationTime: '創建時間',
    lastModifiedBy: '最後更新人',
    lastModifiedTime: '最後更新時間',
    operations: '操作',
    createUser: '創建用戶',
    createUserSuccessAlert: '已成功創建用戶',
    edit: '修改',
    editUser: '修改用戶',
    editUserSuccessAlert: '已成功修改',
    operation: '操作',
    name: '名稱',
  },

  creditAllocation: {
    creationTime: '創建時間',
    lastModifiedTime: '最後更新時間',
    orderId: '訂單ID',
    approvalProgress: '審批進度',
    distributorAgentId: '分銷代理ID',
    currency: '貨幣',
    status: '狀態',
    remarks: '備註',
    createdBy: '創建人',
    createRequest: '創建新的申請',
    createTransferRequestSuccessAlert: '創建成功',
    transactionAmount: '交易金額',
    approval_sequence: '審批序號',
    permission: '權限',
    operation_result: '操作結果',
    operated_by: '操作人',
    operation_time: '操作時間',
    operations: '操作',
    availableBalance: '可用餘額: %{balance}',
    insufficientAvailableBalance: '可用餘額不足',
  },
  monthlyRebateReport: {
    clientTab: {
      title: '客戶',
      // Filter
      month: '月份',
      distributorAgentId: '分銷代理ID',
      programName: '計劃名稱',
      pfhNo: 'PFH號碼',
      rebateMode: '分配紅利模式',

      // Table
      currency: '貨幣',
      rebateTransactionAmount: '紅利交易金額',
      programAgentRebateAmount: '大代理紅利金額',
      distributorAgentRebateRate: '分銷代理所獲的紅利比例',
      clientRebateRate: '客戶所獲的紅利比例',
      distributorAgentRebateAmount: '分銷代理紅利金額',
      clientRebateAmount: '客戶紅利金額',
      netDistributorAgentRebateAmount: '淨分銷代理紅利金額',
    },
    distributorAgentTab: {
      title: '分銷代理',
      // Filter
      month: '月份',
      distributorAgentId: '分銷代理ID',
      programName: '計劃名稱',
      rebateMode: '分配紅利模式',

      // Table
      currency: '貨幣',
      distributorAgentRebateAmount: '分銷代理紅利金額',
      clientRebateAmount: '客戶紅利金額',
      netDistributorAgentRebateAmount: '淨分銷代理紅利金額',
    },
  },

  customerSpendingReport: {
    dailyReportTab: {
      title: '日報表',
      month: '月份',
      settlementDate: '結算日',

      distributorAgentId: '分銷代理ID',
      distributorAgentName: '分銷代理名稱',
      pfhNumber: 'PFH編號',
      programName: '計劃名稱（顯示)',
      currency: '貨幣',
      repayment: '還款',
      totalTransactionAmount: '總交易金額',

      localPayment: '本地消費總額',
      localPaymentPfhCost: '本地消費\n(PFH成本)',
      localPaymentPaMarkUp: '本地消費\n(PA營利)',

      localWithdrawal: '本地提款',
      localWithdrawalPfhCost: '本地提款\n(PFH成本)',
      localWithdrawalPaMarkup: '本地提款\n(PA營利)',

      localRefund: '本地退款',
      localRefundPfhCost: '本地退款\n(PFH成本)',
      localRefundPaMarkup: '本地退款\n(PA營利)',

      overseasPayment: '海外消費',
      overseasPaymentPfhCost: '海外消費\n(PFH成本)',
      overseasPaymentPaMarkUp: '海外消費\n(PA營利)',

      overseasWithdraw: '海外提款',
      overseasWithdrawPfhCost: '海外提款\n(PFH成本)',
      overseasWithdrawPaMarkup: '海外提款\n(PA營利)',

      overseasRefund: '海外退款總額',
      overseasRefundPfhCost: '海外退款\n(PFH成本)',
      overseasRefundPaMarkup: '海外退款\n(PA營利)',

      totalPfhCost: 'PFH總成本',
      totalPaMarkup: 'PA總營利',

      annualFee: '年費費用',
      visaRebate: 'VISA紅利',
      paTotalRebate: 'PA總紅利',
    },
    monthlyReportTab: {
      title: '月報表',
      month: '月',
      settlementDate: '結算日期',

      distributorAgentId: '分銷代理ID',
      distributorAgentName: '分銷代理名稱',
      pfhNumber: 'PFH編號',
      programName: '計劃名稱（顯示)',
      currency: '貨幣',
      repayment: '還款',
      totalTransactionAmount: '總交易金額',

      localPayment: '本地消費總額',
      localPaymentPfhCost: '本地消費\n(PFH成本)',
      localPaymentPaMarkUp: '本地消費\n(PA營利)',

      localWithdrawal: '本地提款',
      localWithdrawalPfhCost: '本地提款 \n (PFH成本)',
      localWithdrawalPaMarkup: '本地提款\n(PA營利)',

      localRefund: '本地退款',
      localRefundPfhCost: '本地退款\n(PFH成本)',
      localRefundPaMarkup: '本地退款\n(PA營利)',

      overseasPayment: '海外消費',
      overseasPaymentPfhCost: '海外消費\n(PFH成本)',
      overseasPaymentPaMarkUp: '海外消費\n(PA營利)',

      overseasWithdraw: '海外提款',
      overseasWithdrawPfhCost: '海外提款\n(PFH成本)',
      overseasWithdrawPaMarkup: '海外提款\n(PA營利)',

      overseasRefund: '海外退款總額',
      overseasRefundPfhCost: '海外退款\n(PFH成本)',
      overseasRefundPaMarkup: '海外退款\n(PA營利)',

      totalPfhCost: 'PFH總成本',
      totalPaMarkup: 'PA總營利',

      annualFee: '年費費用',
      visaRebate: 'VISA紅利',
      paTotalRebate: 'PA總紅利',
    },
  },

  distributorAgentList: {
    // commonTranlslateKey
    distributor_name: '分銷代理姓名',
    distributor_agent_id: '分銷代理ID',

    // createAgentDialogTranlslateKey
    create_agent_btn: '創建代理',
    created_agent_success: '代理賬戶創建成功',
    create_agent_dulplicate_warning: '此代理賬戶已存在',

    // assignProgramDialog
    assign_program_btn: '分配計劃',
    selected_program: '已選計劃',
    unassigned_program: '未分配的計劃',
    assign_btn: '分配',
    confirm_assign_program_message:
      '確認分配 %{programName1}, %{programName2} 給 %{distributorAgentID} (%{distributorName})?',
    assign_program_success: '分配成功',

    // programNameAndEditDialogTranlslateKey
    edit_btn: '編輯',
    rebate_mode: '紅利模式',
    da_rebate_rate: '分銷代理紅利比例',
    client_rebate_rate: '客戶紅利比例',
    status: '狀態',

    // filterTableTranslateKey
    operation: '操作',
    creation_time: '創建時間',
    last_modified_time: '最後更新時間',
    program_name: '計劃名稱',
    created_by: '創建人',
    last_modified_by: '最後修改人',
  },
  distributorAgentProgram: {
    // commonTranslateKey
    distributor_name: '分銷代理姓名',
    distributor_agent_id: '分銷代理ID',
    program_name: '計劃名稱',
    program_display_name: '計劃顯示名稱',
    rebate_mode: '紅利模式',
    rebate_rate_to_distributor_agent: '分銷代理所獲的紅利比例',
    rebate_rate_to_distributor_agent_and_client: '分銷代理及客戶紅利比例',
    percentage_to_distributor_agent: '分銷代理佔比',
    percentage_to_client: '客戶佔比',
    status: '狀態',

    // assignProgramDialogTranslateKey
    assign_program_btn: '分配計劃',
    assign_btn: '分配',
    assign_program_success: '分配成功',

    // editDialogTranslateKey
    edit_btn: '編輯',
    edit_success: '編輯成功',

    // filterTableTranslateKey
    operation: '操作',
    rebate_rate_to_client: '客戶所獲的紅利比例',
    rebate: '紅利',
    creation_time: '創建時間',
    last_modified_time: '最後更新時間',
    created_by: '創建人',
    last_modified_by: '最後修改人',
  },

  creditSwapRecord: {
    creation_time: '創建時間',
    last_modified_time: '最後更新時間',
    last_modified_by: '最後修改人',
    pfh_number: 'PFH號碼',
    asset: '資產',
    asset_amount: '資產金額',
    status: '狀態',
    reference_number: '參考編號',
    exchange_rate: '匯率',
    credit_currency: '信用貨幣',
    credit_amount: '信用額度',
    step: '步驟',
    source_transfer_order_id: '起始傳送交易ID',
    target_transfer_order_id: '目標劃轉訂單ID',
    created_by: '創建人',
    operation: '操作',
    confirm_to_retry_the_record: '確認重新執行此記錄？',
    retry: '重新執行',
    retry_successfully: '重新執行成功',
    swap_type: '兌換類型',
  },

  creditAllocationRecord: {
    operation: '操作',
    creation_time: '創建時間',
    last_modified_time: '最後更新時間',
    transfer_order_id: '劃轉訂單ID',
    allocation_type: '分配類型',
    from: '從',
    to: '到',
    credit_currency: '信用貨幣',
    credit_amount: '信用額度',
    status: '狀態',
    created_by: '創建人',
    last_modified_by: '最後修改人',
    retry: '重新執行',
    confirm_to_retry_the_record: '確認重新執行此記錄？',
    retry_successfully: '重新執行成功',
  },

  clientApplication: {
    // commonTranslateKey
    distributor_name: '分銷代理姓名',
    document_details: '文件詳情',
    aml_details: 'AML詳情',
    uploaded_documents: '已上傳文件',

    // filterTableTranslateKey
    distributor_agent_id: '分銷代理ID',
    program_name: '計劃名稱',
    application_status: '申請狀態',
    referral_code: '推薦碼',
    client_name: '客戶姓名',
    email_address: '電郵地址',
    phone_country_code: '電話區號',
    phone_number: '電話號碼',
    pfh_number: 'PFH號碼',
    identification_document_type: '身份證明文件類型',
    identification_number: '身份證明文件號碼',
    date_of_birth: '出生日期',
    pmp_customer_id: 'PMP客戶ID',
    application_step: '申請步驟',
    creation_time: '創建時間',
    last_modified_time: '最後更新時間',
    created_by: '創建人',
    last_modified_by: '最後修改人',

    // uploadedDocumentsDialogTranslateKey
    upload_time: '上傳時間: %{time}',
  },
  rebateDistributionTask: {
    operations: '操作',
    creationTime: '創建時間',
    lastModifiedTime: '最後更新時間',
    currency: '貨幣',

    taskId: '任務ID',
    month: '月份',
    programName: '計劃名稱',
    status: '狀態',
    rebateCurrency: '發放紅利貨幣',
    rebateExchangeRate: '紅利貨幣匯率',
    totalDistributorAgentRebateAmount: '分銷代理紅利總額',
    totalClientRebateAmount: '客戶紅利總額',
    netTotalDistributorAgentRebateAmount: '淨分銷代理紅利總額',
    step: '步驟',
    approvalProgress: '審批進度',

    approval_sequence: '審批序號',
    permission: '權限',
    operation_result: '操作結果',
    operated_by: '操作人',
    operation_time: '操作時間',
    remarks: '備註',
    reject_success_alert: '已拒絕任務',
    approve_success_alert: '已審批任務',
    same_approver_alert: '審批人不能相同',
  },

  rebateRecord: {
    creationTime: '創建時間',
    month: '月份',
    taskId: '任務ID',
    orderId: '訂單編號',
    from: '從',
    to: '到',
    programName: '計劃名稱',
    status: '狀態',
    sequence: '序',
    rebateType: '分配紅利類型',
    currency: '貨幣',
    amount: '金額',
    rebateCurrency: '發放紅利貨幣',
    rebateExchangeRate: '紅利貨幣匯率',
    rebateAmount: '紅利金額',
  },
  tcspAccountApplication: {
    merchantId: 'Merchant Id',
    customerId: 'Customer Id',
    programName: 'Program Name',
    customerNumber: 'Customer Number',
    status: 'Status',
    createdBy: 'Created By',
    creationDate: 'Creation Date',
    lastModifiedBy: 'Last Modified By',
    lastModifiedTime: 'Last Modified Time',
    lastModifiedDate: 'Last Modified Date',
    creationTime: 'Creation Time',
    latestVersion: 'Latest Version',
    tcspCustomerApplicationType: 'TCSP Customer Application Type',
    customerApplicationVersion: 'Customer Application Version',
    version: 'Version',
    isLatestVersion: 'Is Latest Version',
    questionVersion: 'Question Version',
    applicationNumber: 'Application Number',
    approvalProgress: 'Approval Progress',
    operations: 'Operations',
    // approval
    approval_sequence: 'Approval Sequence',
    permission: 'Permission',
    operation_result: 'Operation Result',
    operated_by: 'Operated By',
    operation_time: 'Operation Time',
    remarks: '備註',
    // application details
    personalInfoTitle: ' 個人資料 %{index}',
    title: 'Title',
    firstNameInEng: 'First Name in English',
    lastNameInEng: 'Last Name in English',
    chineseName: 'Chinese Name',
    aliasInEng: 'Alias in English',
    phoneNumber: 'Phone Number',
    email: 'Email',
    identificationDocumentType: 'Identification Document Type',
    identificationNumber: 'Identification Number',
    dateOfIssue: 'Date of Issue',
    dateOfExpiry: 'Date of Expiry',
    dateOfBirth: 'Date of Birth',
    usCitizenship: 'U.S. Citizenship',

    reasonForSettingUpTrustAcc: '在香港設立信託賬戶的原因及其目的',
    personPurportingToActOn: '代表客戶行事的人',

    seniorPublicFigureDeclarationTitle: '重要政治及公職人物宣言',
    seniorPublicFigureDeclaration:
      '本人或本人之直系親屬為重要政治及公職人物（現在/ 曾經任職於本地或海外機構，包括司法機構/軍隊/領事館等）',
    noSeniorPublicFigureDeclaration: '本人或本人之直系親屬並非重要政治及公職人物',
    member: '成員 %{index}',
    firstName: '名字',
    lastName: '姓氏',
    countryOfGov: '國家',
    department: '部門',
    position: '職位',
    relationshipWithCustomer: '與客戶之關係',

    levelOfActivityAnticipatedHeader: '預期戶口活動',
    initialAndOngoingSOurceOfCustomerWealth: '客戶財富或收入的初始和持續來源 (財富來源)',
    sourceAndOriginOfFunds: '所用資金來源 (資金來源)',
    totalVolumeOfAsset: '資產總額 (港元)',
    totalVolumeOfAsset2: '資產總額 (港元)',
    levelOfActivityAnticipatedTitle: '預期戶口活動',
    expectedTransactionAmount: '預期每月交易次總金額',
    applicantSignature: '申請人簽署',
    declarationTermsAndConditions: '聲明和條款及細節',
    wishToEnableAdvancePayment: 'I do not wish to enable Advance Payment.',
    applicationSignature: '申請人簽署',
    wishToEnablePrePayment: 'I do not wish to enable Pre-Payment.',
    signatureDate: '簽署日期',

    reasonForTrustAccount: '將某類型的資產作為抵押品以支付信用卡帳項或增加信用額度',

    others: '其他',
    sourceOfWealth: {
      Others: '其他',
      Employment: '受僱',
      SelfEmployment: '自僱',
      BusinessOwnership: '擁有個人業務',
      LoansGrant: '貸款',
      SpouseParentPartner: '配偶/父母/夥伴資助',
      Inheritance: '遺產',
      TrustFund: '信托基金',
      Investment: '投資',
      SalesOfVirtualAssets: '虛擬資產轉售',
      PersonalRealEstate: '個人物業',
    },

    sourceOfFunds: {
      Others: '其他',
      BusinessIncome: '商業及公司收入',
      PersonalSaving: '個人儲蓄',
      Inheritance: '遺產',
      RentalIncome: '租金收入',
      Investment: '投資',
      SalesOfProperty: '物業轉售',
      SalesOfVirtualAssets: '虛擬資產轉售',
      Pension: '退休金',
      WagesDepositedFromEmployer: '工作收入',
    },

    tcspTotalVolumeOfAsset: {
      Below50000: '低於 50,000',
      '50000To99999': '50,000 - 99,999',
      '100000To199999': '100,000 - 199,999',
      '200000To499999': '200,000 - 499,999',
      '500000To999999': '500,000 - 999,999',
      '1000000OrAbove': '1,000,000 或以上',
    },

    tcspExpectedTxPerMonth: {
      '10OrBelow': '低於 10',
      '11To20': '11 - 20',
      '21to30': '21 - 30',
      '31To40': '31 - 40',
      '41To50': '41 - 50',
      '51To60': '51 - 60',
      '61To70': '61 - 70',
      '71To80': '71 - 80',
      '81To90': '81 - 90',
      '91OrAbove': '91 或以上',
    },
    noPersonPurportingToActOn: '否',
    existedTcspApplicationError: '申請已存在',
  },

  distributorAgentWallet: {
    balanceTab: {
      title: '餘額',
      history_button: '歷史',
      operation: '操作',
      creationTime: '創建時間',
      lastModifiedTime: '最後更新時間',
      ledgerClientId: 'Ledger客戶ID',
      clientId: '客戶Id',
      currency: '貨幣',
      balance: '餘額',
      frozenBalance: '凍結餘額',
      lastLedgerTransactionId: 'Ledger最後交易ID',
      distributorAgentId: '分銷代理ID',
    },
    historyTab: {
      title: '歷史',
      order_id: '訂單ID',
      order_type: '訂單類型',
      from: '從',
      to: '到',
      distributor_agent: '分銷代理',
      currency: '貨幣',
      transaction_amount: '交易金額',
      post_balance: '交易後餘額',
      ledger_transaction_id: 'Ledger交易ID',
      remarks: '備註',
      creation_time: '創建時間',
    },
  },

  distributorAgentReportDetail: {
    dailyReportTab: {
      title: '日報表',
      settlementDate: '結算日',
      distributorAgentId: '分銷代理Id',
      programName: '計劃名稱',
      pfhNo: 'PFH號碼',
      currency: '貨幣',
      purchase: '消費',
      refund: '退款',
      accountFunding: '賬戶資金',
      repayment: '還款',
      fundingAdjustment: '資金調整',
      waivedLateCharge: '免除滯納金',
      waivedInterest: '免除利息',
      reward: '回贈',
      withdraw: '提款',
      chargeback: '退款',
      transfer: '劃轉',
      repaymentAdjustment: '還款調整',
      vipServiceFee: 'VIP服務費',
      withholding: '預扣款',
      interest: '利息',
      cashback: '返現',
      fee: '收費',
      feeAdjustment: '收費調整',
      withdrawFeeAdjustment: '提款手續費調整',
      agentMarkupWithdrawFeeAdjustment: '代理加收的提款手續費調整',
      overseaFeeAdjustment: '海外手續費調整',
      agentMarkupOverseaFeeAdjustment: '代理加收的海外交易手續費調整',
      ukFeeAdjustment: '英國手續費調整',
      e6Fee: 'E6收費',
      annualFee: '年費',
      fastFund: '特快現金',
      loadReversal: '退款',
      latePaymentFee: '滯納金',
      others: '其他',
      totalIn: '總收入',
      totalOut: '總支出',
      totalRebateTransactionAmount: '紅利交易總額',
      totalVisaFee: 'VISA手續費總額',
      totalSwapCost: '代幣轉換總成本',
      totalAgentRebate: '代理紅利總額',
      totalSystemCost: '系統總成本',
      totalProfit: '利潤總額',
    },

    monthlyReportTab: {
      title: '月報表',
      monthly: '每月',
      month: '月',
      distributorAgentId: '分銷代理Id',
      programName: '計劃名稱',
      pfhNo: 'PFH號碼',
      currency: '貨幣',
      purchase: '消費',
      refund: '退款',
      accountFunding: '賬戶資金',
      repayment: '還款',
      fundingAdjustment: '資金調整',
      waivedLateCharge: '免除滯納金',
      waivedInterest: '免除利息',
      reward: '回贈',
      withdraw: '提款',
      chargeback: '退款',
      transfer: '劃轉',
      repaymentAdjustment: '還款調整',
      vipServiceFee: 'VIP服務費',
      withholding: '預扣款',
      interest: '利息',
      cashback: '返現',
      fee: '收費',
      feeAdjustment: '收費調整',
      withdrawFeeAdjustment: '提款手續費調整',
      agentMarkupWithdrawFeeAdjustment: '代理加收的提款手續費調整',
      overseaFeeAdjustment: '海外手續費調整',
      agentMarkupOverseaFeeAdjustment: '代理加收的海外交易手續費調整',
      ukFeeAdjustment: '英國手續費調整',
      e6Fee: 'E6收費',
      annualFee: '年費',
      fastFund: '特快現金',
      loadReversal: '退款',
      latePaymentFee: '滯納金',
      others: '其他',
      totalIn: '總收入',
      totalOut: '總支出',
      totalRebateTransactionAmount: '紅利交易總額',
      totalVisaFee: 'VISA手續費總額',
      totalSwapCost: '代幣轉換總成本',
      totalAgentRebate: '代理紅利總額',
      totalSystemCost: '系統總成本',
      totalProfit: '利潤總額',
    },
  },
  distributorAgentReport: {
    dailyReportTab: {
      title: '日報表',
      settlementDate: '結算日期',
      distributorAgentId: '分銷代理ID',
      programName: '計劃名稱（顯示)',
      distributorAgentName: '分銷代理名稱',
      currency: '貨幣',
      repayment: '還款(港幣)',
      totalTxAmount: '總交易金額(港幣)',
      payment: '消費(港幣)',
      paMarkup: '代理交易利潤(港幣)',
      annualFee: '年費(港幣)',
      visaRebate: 'Visa紅利(港幣)',
      paRebate: '代理總紅利（港幣)',
    },
    monthlyReportTab: {
      title: '月報表',
      monthly: '每月',
      month: '月',
      distributorAgentName: '分銷代理名稱',
      distributorAgentId: '分銷代理ID',
      programName: '計劃名稱（顯示)',
      currency: '貨幣',
      repayment: '還款(港幣)',
      totalTxAmount: '總交易金額(港幣)',
      payment: '消費(港幣)',
      paMarkup: '代理交易利潤(港幣)',
      annualFee: '年費(港幣)',
      visaRebate: 'Visa紅利(港幣)',
      paRebate: '代理總紅利（港幣)',
    },
  },
  creditAllocationReport: {
    creation_time: '創建時間',
    last_modified_time: '最後更新時間',
    transfer_order_id: '劃撥交易ID',
    allocation_type: '分配類型',
    from: '從',
    to: '到',
    credit_currency: '信用貨幣',
    credit_amount: '信用額',
    status: '狀態',
    created_by: '創建人',
  },

  clientList: {
    // commonTranslateKey
    details: '詳情',
    available_credit: '可用信用額',
    max_credit_limit: '最高信用額',
    overall_credit_limit: '整體信用額',
    total_balance: '總餘額',
    resident_address: '居住地址',
    first_name: '英文名字',
    last_name: '英文姓氏',
    email_address: '電郵地址',
    phone_no: '電話號碼',
    identification_document_type: '身份證明文件類型',
    identification_no: '身份證明文件號碼',
    pfh_no: 'PFH號碼',

    uploaded_document: '已上傳文件',

    address_line_1: '地址的第一行',
    address_line_2: '地址的第二行',
    address_line_3: '地址的第三行',
    postal_code: '郵遞區號',

    delivery_address: '寄送地址',
    // filterTableTranslateKey
    distributor_agent_id: '分銷代理ID',
    program_name: '計劃名稱',
    status: '狀態',
    client_name: '客戶姓名',
    phone_country_code: '電話區號',
    phone_number: '電話號碼',
    pfh_number: 'PFH號碼',
    pmp_customer_id: 'PMP客戶ID',
    creation_time: '創建時間',
    last_modified_time: '最後更新時間',
    created_by: '創建人',
    last_modified_by: '最後修改人',

    detailsTab: {
      title: '詳情',
    },
    financialTab: {
      title: '帳戶狀況',
      balances: '戶口結餘',
      totalBalance: '總餘額',
      creditLimit: '信用額度',
      availableBalance: '可用結餘',
      excessCreditOf: '超額信用',
      overLimitAllowance: '超出限額',
      enabled: '允許',
      disabled: '不允許',
      maxCreditLimit: '最高信用額度',
    },
  },

  clientReport: {
    dailyReportTab: {
      title: '日報表',
      settlementDate: '結算日期',
      distributorAgentId: '分銷代理ID',
      distributorAgentName: '分銷代理名稱',
      programName: '計劃名稱（顯示）',
      pfhNo: 'PFH號碼',
      totalTransactionAmount: '總交易金額（港幣）',
      repayment: '客戶還款（港幣）',
      payment: '消費（港幣）',
      paMarkup: '代理交易利潤（港幣）',
      visaRebate: 'Visa紅利（港幣）',
      paRebate: '代理總紅利（港幣)',
      annualFee: '年費（港幣）',
    },
    monthlyReportTab: {
      title: '月報表',
      month: '月份',
      distributorAgentId: '分銷代理ID',
      distributorAgentName: '分銷代理名稱',
      programName: '計劃名稱（顯示）',
      pfhNo: 'PFH號碼',
      totalTransactionAmount: '總交易金額（港幣）',
      repayment: '客戶還款（港幣）',
      payment: '消費（港幣）',
      paMarkup: '代理交易利潤（港幣）',
      visaRebate: 'Visa紅利（港幣）',
      paRebate: '代理總紅利（港幣)',
      annualFee: '年費（港幣）',
    },
  },

  clientReportDetail: {
    dailyReportTab: {
      title: '日報表',
      settlementDate: '結算日',
      distributorAgentId: '分銷代理Id',
      programName: '計劃名稱',
      pfhNo: 'PFH號碼',
      currency: '貨幣',
      purchase: '消費',
      refund: '退款',
      accountFunding: '賬戶資金',
      repayment: '還款',
      fundingAdjustment: '資金調整',
      waivedLateCharge: '免除滯納金',
      waivedInterest: '免除利息',
      reward: '回贈',
      withdraw: '提款',
      chargeback: '退款',
      transfer: '劃轉',
      repaymentAdjustment: '還款調整',
      vipServiceFee: 'VIP服務費',
      withholding: '預扣款',
      interest: '利息',
      cashback: '返現',
      fee: '收費',
      feeAdjustment: '收費調整',
      withdrawFeeAdjustment: '提款手續費調整',
      agentMarkupWithdrawFeeAdjustment: '代理加收的提款手續費調整',
      overseaFeeAdjustment: '海外手續費調整',
      agentMarkupOverseaFeeAdjustment: '代理加收的海外交易手續費調整',
      ukFeeAdjustment: '英國手續費調整',
      e6Fee: 'E6收費',
      annualFee: '年費',
      fastFund: '特快現金',
      loadReversal: '退款',
      latePaymentFee: '滯納金',
      others: '其他',
      totalIn: '總收入',
      totalOut: '總支出',
      totalRebateTransactionAmount: '紅利交易總額',
      totalVisaFee: 'VISA手續費總額',
      totalSwapCost: '代幣轉換總成本',
      totalAgentRebate: '代理紅利總額',
      totalSystemCost: '系統總成本',
      totalProfit: '利潤總額',
    },

    monthlyReportTab: {
      title: '月報表',
      monthly: '按月',
      month: '月',
      distributorAgentId: '分銷代理Id',
      programName: '計劃名稱',
      pfhNo: 'PFH號碼',
      currency: '貨幣',
      purchase: '消費',
      refund: '退款',
      accountFunding: '賬戶資金',
      repayment: '還款',
      fundingAdjustment: '資金調整',
      waivedLateCharge: '免除滯納金',
      waivedInterest: '免除利息',
      reward: '回贈',
      withdraw: '提款',
      chargeback: '退款',
      transfer: '劃轉',
      repaymentAdjustment: '還款調整',
      vipServiceFee: 'VIP服務費',
      withholding: '預扣款',
      interest: '利息',
      cashback: '返現',
      fee: '收費',
      feeAdjustment: '收費調整',
      withdrawFeeAdjustment: '提款收費調整',
      agentMarkupWithdrawFeeAdjustment: '代理加收的提款手續費調整',
      overseaFeeAdjustment: '海外手續費調整',
      agentMarkupOverseaFeeAdjustment: '代理加收的海外交易手續費調整',
      ukFeeAdjustment: '英國手續費調整',
      e6Fee: 'E6收費',
      annualFee: '年費',
      fastFund: '特快現金',
      loadReversal: '退款',
      latePaymentFee: '滯納金',
      others: '其他',
      totalIn: '總收入',
      totalOut: '總支出',
      totalRebateTransactionAmount: '紅利交易總額',
      totalVisaFee: 'VISA手續費總額',
      totalSwapCost: '代幣轉換總成本',
      totalAgentRebate: '代理紅利總額',
      totalSystemCost: '系統總成本',
      totalProfit: '利潤總額',
    },
  },

  programAgentReportDetail: {
    dailyReportTab: {
      title: '日報表',
      settlementDate: '結算日',
      distributorAgentId: '分銷代理Id',
      programName: '計劃名稱',
      pfhNo: 'PFH號碼',
      currency: '貨幣',
      purchase: '消費',
      refund: '退款',
      accountFunding: '賬戶資金',
      repayment: '還款',
      fundingAdjustment: '資金調整',
      waivedLateCharge: '免除滯納金',
      waivedInterest: '免除利息',
      reward: '回贈',
      withdraw: '提款',
      chargeback: '退款',
      transfer: '劃轉',
      repaymentAdjustment: '還款調整',
      vipServiceFee: 'VIP服務費',
      withholding: '預扣款',
      interest: '利息',
      cashback: '退款',
      fee: '收費',
      feeAdjustment: '收費調整',
      withdrawFeeAdjustment: '提款收費調整',
      agentMarkupWithdrawFeeAdjustment: '代理加收的提款手續費調整',
      overseaFeeAdjustment: '海外手續費調整',
      agentMarkupOverseaFeeAdjustment: '代理加收的海外交易手續費調整',
      ukFeeAdjustment: '英國手續費調整',
      e6Fee: 'E6收費',
      annualFee: '年費',
      fastFund: '特快現金',
      loadReversal: '退款',
      latePaymentFee: '滯納金',
      others: '其他',
      totalIn: '總收入',
      totalOut: '總支出',
      totalRebateTransactionAmount: '紅利交易總額',
      totalVisaFee: 'VISA手續費總額',
      totalSwapCost: '代幣轉換總成本',
      totalAgentRebate: '代理紅利總額',
      totalSystemCost: '系統總成本',
      totalProfit: '利潤總額',
      totalRebateTxAmount: '紅利交易總額',
    },
    monthlyReportTab: {
      title: '月報表',
      monthly: '按月',
      month: '月',
      distributorAgentId: '分銷代理Id',
      programName: '計劃名稱',
      pfhNo: 'PFH號碼',
      currency: '貨幣',
      purchase: '消費',
      refund: '退款',
      accountFunding: '賬戶資金',
      repayment: '還款',
      fundingAdjustment: '資金調整',
      waivedLateCharge: '免除滯納金',
      waivedInterest: '免除利息',
      reward: '回贈',
      withdraw: '提款',
      chargeback: '退款',
      transfer: '劃轉',
      repaymentAdjustment: '還款調整',
      vipServiceFee: 'VIP服務費',
      withholding: '預扣款',
      interest: '利息',
      cashback: '返現',
      fee: '收費',
      feeAdjustment: '收費調整',
      withdrawFeeAdjustment: '提款手續費調整',
      agentMarkupWithdrawFeeAdjustment: '代理加收的提款手續費調整',
      overseaFeeAdjustment: '海外手續費調整',
      agentMarkupOverseaFeeAdjustment: '代理加收的海外交易手續費調整',
      ukFeeAdjustment: '英國手續費調整',
      e6Fee: 'E6收費',
      annualFee: '年費',
      fastFund: '特快現金',
      loadReversal: '退款',
      latePaymentFee: '滯納金',
      others: '其他',
      totalIn: '總收入',
      totalOut: '總支出',
      totalRebateTransactionAmount: '紅利交易總額',
      totalVisaFee: 'VISA手續費總額',
      totalSwapCost: '代幣轉換總成本',
      totalAgentRebate: '代理紅利總額',
      totalSystemCost: '系統總成本',
      totalProfit: '利潤總額',
    },
  },

  programAgentReport: {
    dailyReportTab: {
      title: '日報表',
      settlementDate: '結算日期',
      programName: '計劃名稱（顯示）',
      repayment: '客戶還款（港幣）',
      totalTransactionAmount: '總交易金額（港幣）',
      payment: '消費（港幣）',
      paMarkup: '代理交易利潤（港幣）',
      annualFee: '年費（港幣)',
      visaRebate: 'Visa紅利 (港幣)',
      paRebate: '代理總紅利（港幣)',
      currency: '貨幣',
    },
    monthlyReportTab: {
      title: '月報表',
      month: '月份',
      settlementDate: '結算日期',
      programName: '計劃名稱（顯示）',
      repayment: '客戶還款（港幣）',
      totalTransactionAmount: '總交易金額（港幣）',
      payment: '消費（港幣）',
      paMarkup: '代理交易利潤（港幣）',
      annualFee: '年費（港幣',
      visaRebate: 'Visa紅利 (港幣)',
      paRebate: '代理總紅利（港幣)',
      currency: '貨幣',
    },
  },

  TcspExpectedTxAmountPerMonth: {
    '10000OrBelow': '低於 10,000',
    '10001To30000': '10,001 - 30,000',
    '30001To50000': '30,001 - 50,000',
    '50001To100000': '50,001 - 100,000',
    '100001To300000': '100,001 - 300,000',
    '300001To500000': '300,001 - 500,000',
    '500001OrAbove': '500,001 或以上',
  },

  exchangeRateConfiguration: {
    operation: '操作',
    createdBy: '創建人',
    creationDate: '創建日期',
    creationTime: '創建時間',
    lastModifiedBy: '最後操作人',
    lastModifiedTime: '最後操作時間',
    lastModifiedDate: '最後操作日期',
    programName: '計劃名稱',
    from: '從',
    to: '到',
    currentExchangeRate: '現時設置匯率',
    adjustmentType: '調整類型',
    adjustmentAmount: '調整額',
    exchangeRateAfterAdjustment: '調整後匯率',

    editDialog: {
      successAlert: '修改成功',
      noChangeAlert: '請修改',
    },
    createRecordDialog: {
      successAlert: '已成功創建記錄',
      duplicateAlert: 'The record already exists',
      negativeAdjustedExchangeRateAlert: '調整後的匯率必須大於 0',
    },
  },

  feeAdjustmentRate: {
    selectProgram: '選擇計劃',
    programName: '計劃名稱',
    result: '查詢結果',

    purchase: '消費',
    refund: '退款',
    withdraw: '提款',

    countryRegion: '國家/地區',
    all: '全部',
    hk: '香港',
    oversea: '海外',
    gb: '英國',

    currency: '貨幣',
    hkd: 'HKD',
    nonHkd: '非HKD',

    cardAcceptorCountry: '交易國家/地區',
    acquirerCurrency: '交易貨幣',

    localFee: '本地交易手續費',
    overseasFee: '海外交易手續費',
    gbFee: '英國交易手續費',
    localAgentMarkup: '代理本地交易調整',
    overseaAgentMarkup: '代理海外交易調整',

    noRecords: '沒有相關記錄',
  },
  programList: {
    creationTime: '創建時間',
    programName: '計劃名稱',
    questionType: '申請表類型',
    programDisplayName: '計劃顯示名稱',
    kycIdvMethod: 'IDV方式',
    programCurrency: '計劃貨幣',
    assignedTo: '已分配到',
    lastModifiedTime: '最後更新時間',
    distributorAgentId: '分銷代理ID',
    distributorAgentName: '分銷代理名稱',
  },
};

export default json;
