import { ChangeEvent, useCallback, useMemo } from 'react';

import {
  EnumCreditSwapStatus,
  EnumSwapType
} from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/SwapAgent';

import SystemCurrencySingleSelection from '../../../../common/filterTable/components/Filters/SystemCurrencySingleSelection';
import { MpTextField, SingleSelection } from '../../../../components';
import { useDatePicker } from '../../../../components/DatePicker';
import FilterTemplate from '../../../../components/Layout/FilterTemplate';
import { SelectChangeEvent } from '../../../../components/MuiGenerals';
import { useTranslation } from '../../../../hooks';
import { useFilterFields, useGenFilterFields } from '../../../../hooks/useFilter';
import { Iprefix } from '../../../../hooks/useTranslation';
import { useZusDialogStore } from '../../../../zustand/store';
import { IsearchParams, useZusParams } from '../hooks';
import { filterTableTranslateKey as TK } from '../mappings/translate';

const initFields = {
  pfhNumber: '',
  asset: '',
  status: '',
  referenceNumber: '',
  targetTransferOrderId: '',
  swapType: ''
};

export default function Filter() {
  const translatePrefix: Iprefix = 'creditSwapRecord';
  const { t, tc } = useTranslation(translatePrefix);
  const zusDialog = useZusDialogStore();

  const DateFields = { CreationDate: useDatePicker(), LastModifiedDate: useDatePicker() };

  const { onReset, fields, setFields } = useFilterFields({
    initFields,
    dateFields: DateFields,
  });

  const zusParams = useZusParams();

  const apiParams: IsearchParams = useMemo(() => {
    const {
      pfhNumber: customerNumber,
      asset: assetCurrency,
      referenceNumber: depositRefNo,
      status,
      targetTransferOrderId,
      swapType
    } = fields;
    const { start: createdDateFrom, end: createdDateTo } = DateFields.CreationDate;
    const { start: lastModifiedDateFrom, end: lastModifiedDateTo } = DateFields.LastModifiedDate;

    return {
      assetCurrency,
      customerNumber,
      depositRefNo,
      status,
      createdDateFrom,
      createdDateTo,
      targetTransferOrderId,
      swapType,
      lastModifiedDateFrom,
      lastModifiedDateTo,
    };
  }, [
    fields,
    DateFields.CreationDate.start,
    DateFields.CreationDate.end,
    DateFields.LastModifiedDate.start,
    DateFields.LastModifiedDate.end,
  ]);

  const onSearch = useCallback(() => {
    zusParams.setBody({ ...apiParams, page: 0 });
    zusParams.refetch();
  }, [apiParams]);

  const onChange =
    (field: keyof typeof fields) =>
      (e: SelectChangeEvent<string> | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFields((f) => ({ ...f, [field]: e.target.value }));
      };

  const { filterFields } = useGenFilterFields(
    [
      { label: t(TK.creationTime), element: <DateFields.CreationDate.Picker type={'dateTime'} /> },
      {
        label: t(TK.lastModifiedTime),
        element: <DateFields.LastModifiedDate.Picker type={'dateTime'} />,
      },

      {
        label: t(TK.pfhNumber),
        element: (
          <MpTextField
            label={tc('phInputField', { fieldName: t(TK.pfhNumber) })}
            value={fields.pfhNumber}
            onChange={onChange('pfhNumber')}
          />
        ),
      },
      {
        label: t(TK.asset),
        element: (
          <SystemCurrencySingleSelection
            onClear={() => () => setFields((prev) => ({ ...prev, asset: '' }))}
            label={tc('phInputField', { fieldName: t(TK.asset) })}
            value={fields.asset}
            onChange={onChange('asset')}
            isSelectingCurrency
          />
        ),
      },
      {
        label: t(TK.status),
        element: (
          <SingleSelection
            label={tc('phInputField', { fieldName: t(TK.status) })}
            value={fields.status}
            onChange={onChange('status')}
            clearSelect={() => setFields((prev) => ({ ...prev, status: '' }))}
            enumData={EnumCreditSwapStatus}
          />
        ),
      },
      {
        label: t(TK.referenceNumber),
        element: (
          <MpTextField
            label={tc('phInputField', { fieldName: t(TK.referenceNumber) })}
            value={fields.referenceNumber}
            onChange={onChange('referenceNumber')}
          />
        ),
      },
      {
        label: t(TK.targetTransferOrderId),
        element: (
          <MpTextField
            label={tc('phInputField', { fieldName: t(TK.targetTransferOrderId) })}
            value={fields.targetTransferOrderId}
            onChange={onChange('targetTransferOrderId')}
          />
        ),
      },
      {
        label: t(TK.swapType),
        element: (
          <SingleSelection
            label={tc('phSelection', { fieldName: t(TK.swapType) })}
            value={fields.swapType}
            onChange={onChange('swapType')}
            enumData={EnumSwapType}
            clearSelect={() => setFields((prev) => ({ ...prev, status: '' }))}
          />
        )
      }
    ],
    [fields, DateFields]
  );

  return <FilterTemplate filterFields={filterFields} onSearch={onSearch} onReset={onReset} />;
}
